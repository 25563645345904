/*****************************************************/
/* EMPRESA:     Tecnología y Conocimiento            
/* Proyecto:    Minimiso                              
/* Autor:       Yeray Suárez
/* Archivo:     checklist.component.ts
/* Fecha:       20190320 
/* Vesion:      1.0.0 
/*****************************************************/
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { serverUrl } from '../../../helper/url';

import { catchError, retry } from 'rxjs/operators';
import { AutenticacionService } from '../../login/service/autenticacion.service';
import { strictEqual } from 'assert';

@Injectable({
  providedIn: 'root'
})
export class ReporteService {

  constructor(private http: HttpClient, private autenticacionService: AutenticacionService) {}

  /**
   * Función que nos permite obtener el listado de informes
   */
  getReportList(){
    return this.http.get<any[]>(`${serverUrl}/report/list`)
    .pipe(
      //tap(res => console.log(res)),
      catchError(err => {
        console.log(err);
        return of(err);
      })
    );
  }

  /**
   * Función que nos permite obtener el listado de parámetros y datos asociados de un reporte
   * @param idreporte Identificador del informe 
   */
  getParameters(idreporte: string){
    return this.http.get<any[]>(`${serverUrl}/report/reportconfig?esquema=${this.autenticacionService.getEsquemaUser()}&idreport=${idreporte}`)
    .pipe(
      //tap(res => console.log(res)),
      catchError(err => {
        console.log(err);
        return of(err);
      })
    );
  }

  /**
   * Función que nos permite obtener los datos de una consulta proporcionada por un parámetro.
  */
  getData(url: string){
    if(url.indexOf('?')!=-1){
      let urlbase= url.substring(0, (url.indexOf('?')+1)) + `esquema=${this.autenticacionService.getEsquemaUser()}&`;
      let urlparams= url.substring(url.indexOf('?')+1);
      return this.http.get<any[]>(`${serverUrl}${urlbase}${urlparams}`)
      .pipe(
        //tap(res => console.log(res)),
        catchError(err => {
          console.log(err);
          return of(err);
        })
      );
    }else{
      let newurl= url + `?esquema=${this.autenticacionService.getEsquemaUser()}`;
      return this.http.get<any[]>(`${serverUrl}${newurl}`)
      .pipe(
        //tap(res => console.log(res)),
        catchError(err => {
          console.log(err);
          return of(err);
        })
      );
    }
  }

}
