import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './component/home/home.component';
import { LoginComponent } from './module/login/login/login.component';
import { AuthGuard } from './guards/auth.guard';
import { FormforgotpassComponent } from './module/forgotpass/formforgotpass/formforgotpass.component';
import { FormchangepassComponent } from './module/forgotpass/formchangepass/formchangepass.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent},
  { path: 'forgot', component: FormforgotpassComponent},
  { path: 'passchange/:uuid', component: FormchangepassComponent},
  { path: ':contenedor/:pagename', component: HomeComponent, canActivate: [AuthGuard]},
  { path: '**', redirectTo: 'home/peticion'},
  { path: '',   redirectTo: '/home/peticion', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
