/*****************************************************/
/* EMPRESA:     Tecnología y Conocimiento            
/* Proyecto:    Minimiso                              
/* Autor:       Yeray Suárez
/* Archivo:     formreport.component.ts
/* Fecha:       20190320 
/* Vesion:      1.0.0 
/*****************************************************/
import { Component, OnInit } from '@angular/core';

import { ReporteService } from './service/reporte.service';

import * as moment from 'moment';
import { AutenticacionService } from '../../module/login/service/autenticacion.service';

@Component({
  selector: 'app-formreport',
  templateUrl: './formreport.component.html',
  styleUrls: ['./../form/form.css']
})
export class FormreportComponent implements OnInit {
  /**
   * Objeto que almacenará la configuración del calendarios de los campos fecha inicio y fecha fin.
   */
  es: object={};
  /**
   * Vector que almacena el listado de posibles informes a seleccionar por el usuario
   */
  listreport: any[]=[];
  /**
   * Vector que almacena el listado de parámetros que posee el informe seleccionado.
   */
  listparameters: any[]=[];
  /**
   * Variable que almacena el identificador del informe seleccionado
   */
  idinforme:string=null;
  /**
   * Variable que almacena la url del informe tras ser montada a partir de los distintos parámetros
   */
  urlreporte: string='';
  
  constructor(private reportService: ReporteService, private autenticacionService: AutenticacionService) {
    this.es = {
      firstDayOfWeek: 1,
      dayNames: [ "domingo","lunes","martes","miércoles","jueves","viernes","sábado" ],
      dayNamesShort: [ "dom","lun","mar","mié","jue","vie","sáb" ],
      dayNamesMin: [ "D","L","M","X","J","V","S" ],
      monthNames: [ "enero","febrero","marzo","abril","mayo","junio","julio","agosto","septiembre","octubre","noviembre","diciembre" ],
      monthNamesShort: [ "ene","feb","mar","abr","may","jun","jul","ago","sep","oct","nov","dic" ],
      today: 'Hoy',
      clear: 'Borrar'
    }
  }

  /**
   * Función que se llama al iniciar el componente. En este caso, lo que hace es buscar el listado de informes mediante el servicio reporte.service.
   */
  ngOnInit() {
    this.reportService.getReportList()
    .subscribe(result=>{
      this.listreport=result;
    });
  }

  /**
   * Al elegir un informe se llama a esta función que busca los parametros mediante el proveedor reporte.service. Cambiando así de forma dinámica toda la vista en función de dichos parámetros
   */
  cambioInforme(){
    this.listparameters=[];
    this.reportService.getParameters(this.idinforme)
    .subscribe(result=>{
      this.listparameters=result;
      if(this.listparameters.length==0){
        this.getReporte();
      }
      this.listparameters=this.listparameters.map(x=>{
        x.value=null;
        x.multiValue=[];
        x.options=[];
        return x;
      });
      for(let lp of result){
        if(lp.servicio!=null && lp.servicio.indexOf('$')==-1){
          this.reportService.getData(lp.servicio)
          .subscribe(result2=>{
            lp.options=result2;
          });
        }
      }
    });
  }
  
  /**
   * Al elegir fechas se llama a esta función que sustituira en todos los parametros dependientes $x por la fecha reescribiendo el atributo llamado newservice. Y si se encuentra completo se llamara al servicio que nos proporcionará a los posibles valores del parámetro en cuestión.
   * @param parametro Objeto que representa al parámetro
   */
  onSelectFecha(parametro: any){
    this.resetDependientes(parametro);
    parametro.value=moment(new Date(parametro.datevalue)).format('YYYY-MM-DD').toString();
    this.solicitarDatos(parametro);
  }

  /**
   * Vacia el estado de todos los parámetros que dependan de el parámetro de entrada.
   * @param parametro Objeto que representa al parámetro
   */
  resetDependientes(parametro: any){
    for(let p of this.listparameters){
      if(p.servicio!=null && p.servicio.indexOf('$'+parametro.idparametro)!=-1){
        p.value=null;
        p.multiValue=[];
        p.options=[];
      }
    }
  }

  /**
   * Función que se lanza cuando se usa el multiselector que sustituira en todos los parametros dependientes $x por la multiselección reescribiendo el atributo newservice. Y si se encuentra completo se llamará al servicio que nos proporcionará los posibles valores del parámetro en cuestión.
   * @param parametro Objeto que representa al parámetro
   */
  onSelectMulti(parametro: any){
    let strValues= '';
    for(let sc of parametro.multiValue){
      if(sc==parametro.multiValue[0]){
        strValues += `${sc}`;
      }else{
        strValues += `,${sc}`;
      }
    }
    this.resetDependientes(parametro);
    parametro.value=strValues;
    this.solicitarDatos(parametro);
  }

  
  /**
   * Función que se lanza cuando se usa el monoselector que sustituira en todos los parametros dependientes $x por la monoselección reescribiendo el atributo newservice. Y si se encuentra completo se llamará al servicio que nos proporcionará los posibles valores del parámetro en cuestión.
   * @param parametro Objeto que representa al parámetro
   */
  onSelectOne(parametro: any){
    this.resetDependientes(parametro);
    this.solicitarDatos(parametro);
  }

  /**
   * Función que busca los datos da algúno de los parámetros con dependencias de otros una vez que esa dependencia se resuelve. Para ello, se utiliza el servicio reportService.
   * @param parametro Objeto que representa al parámetro
   */
  solicitarDatos(parametro: any){
    this.listparameters= this.listparameters.map(p=>{
      if(p.servicio!=null){
        let pos= p.servicio.indexOf('$' + parametro.idparametro);
        if(pos!=-1){
          p.newservicio=p.servicio.replace('$'+parametro.idparametro, parametro.value);
          if(p.newservicio.indexOf('$')!=-1){
            for(let lp of this.listparameters){
              if(lp.idparametro!=p.idparametro){
                if(lp.value!=null){
                  if(p.newservicio.indexOf('$'+ lp.idparametro)){
                    p.newservicio= p.newservicio.replace('$'+lp.idparametro, lp.value);
                  }
                }
              }
            }
            if(p.servicio!=null && p.newservicio.indexOf('$')==-1){
              this.reportService.getData(p.newservicio)
              .subscribe(result=>{
                p.options=result;
              });
            }
          }
        }
      }
      return p;
    });
    this.getReporte();
  }

  /**
   * Comprueba si no existe ningun parámetro cuyo estado esté indefinido, es decir, que hayan resuelto todos sus dependencias y se haya seleccionado un valor.
   */
  seleccionadosTodosParams(){
    for(let p of this.listparameters){
      if((p.value==null || p.value==undefined) && p.obligatorio==1){
        return false;
      }
    }
    return true;
  }

  /**
   * Genera la url para acceder a la plataforma remota y generar el informe
   */
  getReporte(){
    if(this.seleccionadosTodosParams()){
      let esq=this.autenticacionService.getEsquemaUser().toString();
      this.urlreporte=`${this.listreport[0].urlserver}${this.listreport[0].service}user=${this.listreport[0].serveruser}&apikey=${this.listreport[0].apikey}&format=pdf&page=true&download=false&id=${this.idinforme}&p_esquema=${esq}`;
      for(let p of this.listparameters){
        if(p.value!=null){
          this.urlreporte+= `&${p.nombreparametro}=${p.value}`;
        }else{
          this.urlreporte+= `&${p.nombreparametro}=`;
        }
      }
      console.log(this.urlreporte);
    }
  }


  /**
   * Restaura el formulario al estado inicial
   */
  cleanDataNotSaved(){
    this.listparameters=[];
    this.idinforme=null;
    this.urlreporte='';
  }

}
