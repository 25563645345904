import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { FormComponent } from '../form/form.component';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { DataService } from 'src/app/service/data.service';
import Utils from '../../../helper/helper';
import { AuxpeticionService } from '../service/auxpeticion.service';
import { AutenticacionService } from '../../login/service/autenticacion.service';

@Component({
  selector: 'app-form-peticion',
  templateUrl: './form-peticion.component.html',
  styleUrls: ['./../form.css']
})
export class FormPeticionComponent extends FormComponent implements OnInit {
  @ViewChild('closeModal', {static: false}) protected closeModal: ElementRef;
  @Input() tipopeticion: any;
  //@Input() departamento: any;
  @Input() reportador: any;
  @Input() prioridad: any;
  listprioridad: any[]=[];
  @Input() categoria: any;
  @Input() estancia: any;
  @Input() duracion: any;
  @Input() creador: any;

  bloque: any[]=[];
  area: any[]=[];
  planta: any[]=[];
  personal: any[]=[];
  personalasignado: any[]=[];
  restrigirPrioridad=false;

  constructor(private formBuilder: FormBuilder, public dataservice: DataService, private auxpeticionservice: AuxpeticionService, private autenticacionService: AutenticacionService) { 
    super(dataservice);
    this.setCloseModel(this.closeModal);
  }

  ngOnInit() {
    this.restrigirPrioridad=false;
    this.preload();
  }

  preload(){
    if(this.data==null){
      this.initValues();
    }else{
      this.auxpeticionservice.getPrioridadByReportador(this.data.idprioridad)
      .subscribe(result=>{
        this.prioridad=result;
        this.prioridad.unshift({value: null, label: '- Prioridad -'});
      });
      this.personalasignado=[];
      if(this.data.personaasignado!=null){
        console.log(this.data.personaasignado);
        let perAsig= this.data.personaasignado.split(',');
        for(let p of perAsig){
          this.personalasignado.push(p);
        }
      }
      this.auxpeticionservice.getPersonal()
        .subscribe(result=>{
          this.personal=result;
      });
      this.loadValues();
    }
    this.startForm();
  }

  startForm(){
    this.dataform = this.formBuilder.group({
      idreportador: [this.predata.idreportador, Validators.compose([Validators.required])],
      identificacioncreador:[this.predata.identificacioncreador, Validators.compose([Validators.required])],
      personaAsignado: [this.personalasignado],
      idprioridad: [this.predata.idprioridad, Validators.compose([Validators.required])],
      idbloque: [this.predata.idbloque],
      idarea: [this.predata.idarea],
      idplanta: [this.predata.idplanta],
      idestancia: [this.predata.idestancia, Validators.compose([Validators.required])],
      descripcion: [this.predata.descripcion, Validators.compose([Validators.required])],
      idcategoria: [this.predata.idcategoria],
      solucion: [this.predata.solucion]
    });

    this.dataform.get('idestancia').valueChanges.subscribe(val => {
      this.dataform.controls['idbloque'].setValue(this.getBloqueFromEstancia(val));
      this.dataform.controls['idarea'].setValue(this.getAreaFromEstancia(val));
      this.dataform.controls['idplanta'].setValue(this.getPlantaFromEstancia(val));
    });
    this.dataform.get('idreportador').valueChanges.subscribe(val => {
      this.auxpeticionservice.getPrioridadByReportador(val)
      .subscribe(result=>{
        this.prioridad=result;
        this.prioridad.unshift({value: null, label: '- Prioridad -'});
      })
    })
  }

  getBloqueFromEstancia(id){
    console.log(id);
    for(let e of this.estancia){
      console.log(e.value);
      if(e.value==id){
        return e.idbloque;
      }
    }
  }

  getAreaFromEstancia(id){
    for(let e of this.estancia){
      if(e.idestancia==id){
        return e.idarea;
      }
    }
  }

  getPlantaFromEstancia(id){
    for(let e of this.estancia){
      if(e.idestancia==id){
        return e.idplanta;
      }
    }
  }

  initValues(){
    this.errorSaving=false;
    this.predata= {
      idreportador: null,
      identificacioncreador: null,
      idcategoria: null,
      personaAsignado: null,
      idprioridad: null,
      idbloque: null,
      idarea: null,
      idplanta: null,
      idestancia: null,
      descripcion: null,
      idduracion: null,
      solucion: null
    };
  }

  loadValues(){
    this.predata= {
      idreportador: this.data.idreportador,
      identificacioncreador: this.data.identificacioncreador,
      personaAsignado: this.data.personaAsignado,
      idcategoria: this.data.idcategoria,
      idprioridad: this.data.idprioridad,
      idbloque: this.data.idbloque,
      idarea: this.data.idarea,
      idplanta: this.data.idplanta,
      idestancia: this.data.idestancia,
      descripcion: this.data.descripcion,
      idduracion: this.data.idduracion,
      solucion: this.data.solucion
    };
  }

  /**
   * Genera el objeto en función de centroform a enviar a anadirCentro.
   */
  saveData(){
    let object=null;
    if(this.data==null){
      object ={
        idreportador: this.dataform.value.idreportador,
        identificacioncreador: this.dataform.value.identificacioncreador,
        personaAsignado: this.dataform.value.personaAsignado,
        idcategoria: this.dataform.value.idcategoria,
        idprioridad: this.dataform.value.idprioridad,
        idbloque: this.dataform.value.idbloque,
        idarea: this.dataform.value.idarea,
        idplanta: this.dataform.value.idplanta,
        idestancia: this.dataform.value.idestancia,
        descripcion: Utils.cleanSpaceString(this.dataform.value.descripcion),
        idduracion: this.dataform.value.idduracion,
        solucion: Utils.cleanSpaceString(this.dataform.value.solucion),
        idusuario: this.autenticacionService.getIdUser()
      };
      console.log(this.dataform);
      this.anadir(object);
    }else{
      let estado= this.data.idestado;
      if((this.dataform.value.personaAsignado.length==0 && this.dataform.value.solucion!=null)){
        this.errorSaving=true;
        this.errorMessage='No es posible cerrar una petición sin haber asignado personal a la misma';
      }else{
        if(this.dataform.value.solucion!=null){
          estado= 3;
        }
        object ={
          idreportador: this.dataform.value.idreportador,
          identificacioncreador: this.dataform.value.identificacioncreador,
          personaAsignado: this.dataform.value.personaAsignado,
          idcategoria: this.dataform.value.idcategoria,
          idprioridad: this.dataform.value.idprioridad,
          idbloque: this.dataform.value.idbloque,
          idarea: this.dataform.value.idarea,
          idplanta: this.dataform.value.idplanta,
          idestancia: this.dataform.value.idestancia,
          descripcion: Utils.cleanSpaceString(this.dataform.value.descripcion),
          idduracion: this.dataform.value.idduracion,
          solucion: Utils.cleanSpaceString(this.dataform.value.solucion),
          idestado: estado,
          identificacion: this.data.identificacion,
          fechaalta: this.data.fechaalta,
          fechaestimada: this.data.fechaestimada,
          fecharesolucion: this.data.fechabaja,
          idusuario: this.autenticacionService.getIdUser()
        };
        this.modificar(object);
      }
    }
  }

  obtenerMensajeError(tipo: string){
    switch (tipo){
      case 'errorReportador':
        return 'Es necesario seleccionar un reportador';
      case 'errorCreador':
        return 'Es necesario seleccionar un creador';
      case 'errorPrioridad':
        return 'Es necesario seleccionar una prioridad';
      case 'errorBloque':
        return 'Es necesario seleccionar una prioridad';
      case 'errorArea':
        return 'Es necesario seleccionar una área';
      case 'errorPlanta':
        return 'Es necesario seleccionar una planta';
      case 'errorEstancia':
        return 'Es necesario seleccionar una estancia';
      case 'errorDescripcion':
        return 'Es necesario indicar una breve descripción del problema';
      case 'errorSolucion':
        return 'Es necesario indicar la solución tomada para resolver la petición';
      case 'errorCategoria':
        return 'Es necesario seleccionar una categoría';
      case 'errorDescripcion':
        return 'Valor no válido';
      default:
        return 'Error desconocido';
    }
  }

  
}
