import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FromTo } from 'moment';
import { ForgotpassService } from '../service/forgotpass.service';

@Component({
  selector: 'app-formchangepass',
  templateUrl: './formchangepass.component.html',
  styleUrls: ['./../forgotpass.css']
})
export class FormchangepassComponent implements OnInit {
  public error=false;
  public error2= false;
  public error3= false;
  public error4= false;
  public info= false;

  public password='';
  public password2='';
  private uuid='';

  constructor(private route: ActivatedRoute, private forgotpassService: ForgotpassService) { }

  ngOnInit() {
    this.route.params.subscribe( params => {
      this.uuid=params.uuid;
    });
  }

  sendNewPass(){
    if(this.password==this.password2){
      if(this.password.length>5){
        let object={uuid: this.uuid, password: this.password};
        this.forgotpassService.sendNewPass(object)
        .subscribe(result=>{
          if(result.error.error==true){
            if(result.status==409){
              this.error= false;
              this.error4= true;
              this.error3= false;
              this.error2=false;
              this.info=false;
            }else{
              this.error= true;
              this.error4= false;
              this.error3=false;
              this.error2=false;
              this.info=false;
            }
            this.password='';
            this.password2='';
          }else{
            this.error=false;
            this.error2=false;
            this.error3=false;
            this.error4= false;
            this.info=true;
            this.password='';
            this.password2='';
          }
        });
      }else{
        this.error3=true;
        this.error=false;
        this.error2=false;
        this.error4=false;
        this.info=false;
      }
    }else{
      this.error2=true;
      this.error=false;
      this.error3=false;
      this.error4=false;
      this.info=false;
    }
  }
  cleanMessage(){
    this.error2=false;
    this.error=false;
    this.error3=false;
    this.error4=false;
    this.info=false;
  }
}
