import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AutenticacionService } from '../service/autenticacion.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  /**
   * Variable que contiene el usuario y contraseña.
   */
  model: any = {};
  /**
   * Valor booleano que determinará si se muestra una imagen de cargando o no.
   */
  loading = false;
  /**
   * Cadena de caracteres que almacena el mensaje de error en caso de no realizar el login correctamente. 
   */
  error = '';

  constructor(private route: ActivatedRoute,
    private router: Router,
    private autenticacionService: AutenticacionService) { }

  /**
   * Hace la autenticacion con el servicio autenticacion.service. Si se falla más de 4 veces se motrará un captcha.
   */  
  login() {
    this.loading = true;
    this.autenticacionService.login(this.model.username, this.model.password)
    .subscribe(data=>{
      if(data){
        //localStorage.removeItem('loginAttemps');
        this.router.navigate(['/menu/peticion']);
      }else{
        this.loading=false;
        this.error='Usuario o contraseña incorrecta';
      }
    },
    error =>{
      this.loading=false;
      this.error="Usuario o contraseña incorrecta"
    });
  }
}
