import { Injectable } from '@angular/core';
import { catchError, retry } from 'rxjs/operators';
import { serverUrl } from '../../../helper/url';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AutenticacionService } from '../../login/service/autenticacion.service';

@Injectable({
  providedIn: 'root'
})
export class FieldsService {

  constructor(private http: HttpClient, private autenticacionservice: AutenticacionService) { }

    /**
   * Función que nos permite obtener el listado de campos y configuracion de las tablas para un tipo de elemento.
   * @param service 
   */
  getFields(service: string): Observable<any[]>{
    return this.http.get<any[]>(`${serverUrl}/ui/${service}/fields`)
      .pipe(
        //tap(res => console.log(res)),
        catchError(err => {
          console.log(err);
          return of(err);
        })
      );
  }

  getFilters(service: string): Observable<any[]>{
    return this.http.get<any[]>(`${serverUrl}/ui/${service}/filters/${this.autenticacionservice.getEsquemaUser()}?idusuario=${this.autenticacionservice.getIdUser()}`)
      .pipe(
        //tap(res => console.log(res)),
        catchError(err => {
          console.log(err);
          return of(err);
        })
      );
  }
}
