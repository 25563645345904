import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { CommonModule } from '@angular/common';

import { TablaComponent } from './tabla/tabla.component';
import { FieldsService } from './service/fields.service';


import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/primeng';
import { MultiSelectModule } from 'primeng/multiselect';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FormModule } from '../../module/form/form.module';
import { FormreportModule } from '../../module/formreport/formreport.module';

import { InputTextModule } from 'primeng/inputtext';
import {TabViewModule} from 'primeng/tabview';
import { InfoDeleteComponent } from '../../module/info/info-delete/info-delete.component';
import { InfoStatusComponent } from '../../module/info/info-status/info-status.component';

@NgModule({
  declarations: [
    TablaComponent,
    InfoDeleteComponent,
    InfoStatusComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    TableModule,
    CalendarModule,
    DropdownModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    FormreportModule,
    HttpClientModule,
    FormModule,
    InputTextModule,
    TabViewModule
  ],
  exports: [TablaComponent],
  providers: [FieldsService],
})
export class TablaModule { }
