import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { FormComponent } from '../form/form.component';
import { FormBuilder, Validators } from '@angular/forms';
import { DataService } from 'src/app/service/data.service';
import { AutenticacionService } from '../../login/service/autenticacion.service';
import Utils from '../../../helper/helper';

@Component({
  selector: 'app-form-access',
  templateUrl: './form-access.component.html',
  styleUrls: ['./../form.css']
})
export class FormAccessComponent extends FormComponent implements OnInit {
  @ViewChild('closeModal', {static: false}) protected closeModal: ElementRef;
  @Input() privilegio: any;
  @Input() departamento: any;

  constructor(private formBuilder: FormBuilder, public dataservice: DataService, private autenticacionService: AutenticacionService) {
    super(dataservice);
    this.setCloseModel(this.closeModal);
  }

  ngOnInit() {
    this.preload();
  }

  preload(){
    if(this.data==null){
      this.initValues();
    }else{
      this.loadValues();
    }
    this.startForm();
  }

  initValues(){
    this.errorSaving=false;
    this.predata= {
      username: null,
      email: null,
      iddepartamento: null,
      idprivilegio: null,
      idactivo: null
    };
  }

  startForm(){
    this.dataform = this.formBuilder.group({
      username: [this.predata.username, Validators.compose([Validators.required])],
      email: [this.predata.email, Validators.compose([Validators.required, Validators.email])],
      iddepartamento: [this.predata.iddepartamento, Validators.compose([Validators.required])],
      idprivilegio: [this.predata.idprivilegio, Validators.compose([Validators.required])],
      idactivo: this.predata.idactivo
    });
  }

  loadValues(){
    this.predata= {
      username: this.data.username,
      email: this.data.email,
      iddepartamento: this.data.iddepartamento,
      idprivilegio: this.data.idprivilegio,
      idactivo: this.data.idactivo
    };
  }

  saveData(){
    let object=null;
    if(this.data==null){
      object ={
        username: Utils.cleanSpaceString(this.dataform.value.username),
        email: Utils.cleanSpaceString(this.dataform.value.email),
        iddepartamento: this.dataform.value.iddepartamento,
        idprivilegio: this.dataform.value.idprivilegio,
        idactivo: this.predata.idactivo
      };
      this.anadir(object);
    }else{
      object ={
        idusuario: this.data.idusuario,
        username: Utils.cleanSpaceString(this.dataform.value.username),
        email: Utils.cleanSpaceString(this.dataform.value.email),
        iddepartamento: this.dataform.value.iddepartamento,
        idprivilegio: this.dataform.value.idprivilegio,
        idactivo: this.predata.idactivo
      };
      this.modificar(object);
    }
  }


  obtenerMensajeError(tipo: string){
    switch (tipo){
      case 'errorNombre':
        return 'Es necesario añadir un nombre de usuario';
      case 'errorEmail':
        return 'Es necesario añadir un email en el formato adecuado';
      case 'errorDepartamento':
        return 'Es necesario seleccionar un departamento';
      case 'errorPrivilegio':
        return 'Es necesario seleccionar un privilegio';
      default:
        return 'Error desconocido';
    }
  }

}
