import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormforgotpassComponent } from './formforgotpass/formforgotpass.component';
import { FormchangepassComponent } from './formchangepass/formchangepass.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule }    from '@angular/common/http';
import { ForgotpassService } from './service/forgotpass.service';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [FormchangepassComponent, FormforgotpassComponent],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    RouterModule
  ],
  providers: [ForgotpassService],
})
export class ForgotpassModule { }
