import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { serverUrl } from '../../../helper/url';
import { tap, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

const options = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class AutenticacionService {
  private token: string;
  private esquema= null;
  private privilegios= null;
  private usuario=null;
  private idusuario=null;

  constructor(private http: HttpClient, private router: Router) { 
    if(localStorage.getItem('dataUserPimbam')!=null){
      let currentUser= JSON.parse(localStorage.getItem('dataUserPimbam'));
      this.token= currentUser.token;
      this.esquema= currentUser.esquema;
      this.privilegios= currentUser.privilegios;
      this.usuario= currentUser.username;
      this.idusuario= currentUser.idusuario;
    }
  }

  /**
   * Función que permite obtener el token.
   */
  getAuthToken(){
    return this.token;
  }

  /**
   * Función que retorna el nivel de privilegios del usuario
   */
  getPrivilegiosUser(){
    return this.privilegios;
  }

  /**
   * Función que permite obtener el esquema asociado al usuario
   */
  getEsquemaUser(){
    return this.esquema;
  }

  /**
   * Función que permite obtener el usuario
   */
  getUsername(){
    return this.usuario;
  }

  getIdUser(){
    return this.idusuario;
  }

  /**
   * Función que verificar mediante el backend la existencia del usuario y guardar los datos retornados por el servicio node.
   * @param usuario Cadena de caracteres del nombre de usuario
   * @param contrasena Cadena de caracteres de la contraseña
   */
  login(usuario: string, contrasena: string): Observable<Boolean>{
    let obj= {username: usuario, password: contrasena};
    let data= JSON.stringify(obj);
    return this.http.post<any>(`${serverUrl}/token/authenticate`, data, options)
      .pipe(
        tap(response =>{
          if(response && response.token!=''){
            localStorage.setItem('dataUserPimbam', JSON.stringify({ idusuario: response.idusuario, username: response.username, esquema: response.esquema, privilegios: response.privilegios, token: response.token, refreshtoken: response.refreshtoken }));
            this.token = response.token;
            this.esquema= response.esquema;
            this.usuario= response.username;
            this.idusuario= response.idusuario;
            this.privilegios= response.privilegios;
            return true;
          }
          return false;
        })
      );
  }

  /**
   * Función que solicita el refreshtoken
   */
  refreshTokenAccess(): Observable<Boolean>{
    var aux = JSON.parse(localStorage.getItem('dataUserPimbam'));
    let obj= { idusuario: aux.idusuario, username: aux.username, esquema: aux.esquema, privilegios: aux.privilegios, refreshtoken: aux.refreshtoken };
    let data= JSON.stringify(obj);
    return this.http.post<any>(`${serverUrl}/token/refresh`, data, options)
        .pipe(
          tap(response =>{
            if(response && response.token!=''){
              var idusuario= aux.idusuario;
              var username= aux.username;
              var privilegios= aux.privilegios;
              var esquema= aux.esquema;
              localStorage.removeItem('dataUserPimbam');
              localStorage.setItem('dataUserPimbam', JSON.stringify({ idusuario: idusuario, username: username, privilegios: privilegios, esquema: esquema, token: response.token, refreshtoken: response.refreshtoken }));
              this.token= response.token;
              return true;
            }
            return false;
          })
        );
  }

  /**
   * Función se solicita la baja del login del usuario
   */
  rejectToken():Observable<Boolean>{
    let currentUser = JSON.parse(localStorage.getItem('dataUserPimbam'));
    let tokenrefresh = currentUser.refreshtoken;
    let data = JSON.stringify({refreshtoken: tokenrefresh});
    console.log(`${serverUrl}/token/reject`);
    return this.http.post<any>(`${serverUrl}/token/reject`, data, options)
      .pipe(
        tap(response => {
          console.log(response);
          return this.logout();
        }),
        catchError(err => {
          console.log(err);
          this.logout();
          return of(err);
        })
      );
  }

  /**
   * Función que echa al usuario por que su token refresh esta caducado.
   */
  logout(){
    localStorage.removeItem('dataUserPimbam');
    this.router.navigate(['/login']);
    return true;
  }
}
