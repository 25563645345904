import { Component, OnInit, ViewChild, Input, ElementRef } from '@angular/core';
import { FormComponent } from '../form/form.component';
import { FormBuilder, Validators } from '@angular/forms';
import { DataService } from 'src/app/service/data.service';
import { AutenticacionService } from '../../login/service/autenticacion.service';
import Utils from '../../../helper/helper';

@Component({
  selector: 'app-form-persona',
  templateUrl: './form-persona.component.html',
  styleUrls: ['./../form.css']
})
export class FormPersonaComponent extends FormComponent implements OnInit {
  @ViewChild('closeModal', {static: false}) protected closeModal: ElementRef;
  @Input() departamento: any;
  NUMERIC_PATTREN = '^-?[0-9]\\d*(\\.\\d{1,3})?$'; //accepts only 3 places after the point

  constructor(private formBuilder: FormBuilder, public dataservice: DataService, private autenticacionService: AutenticacionService) {
    super(dataservice);
    this.setCloseModel(this.closeModal);
  }

  ngOnInit() {
    this.preload();
  }

  preload(){
    if(this.data==null){
      this.initValues();
    }else{
      this.loadValues();
    }
    this.startForm();
  }

  initValues(){
    this.errorSaving=false;
    this.predata= {
      nombre: null,
      apellidos: null,
      iddepartamento: null,
      costehorario: null,
      observaciones: null,
      idactivo: 1
    };
  }

  startForm(){
    this.dataform = this.formBuilder.group({
      nombre: [this.predata.nombre, Validators.compose([Validators.required])],
      apellidos: [this.predata.apellidos, Validators.compose([Validators.required])],
      iddepartamento: [this.predata.iddepartamento, Validators.compose([Validators.required])],
      costehorario: [this.predata.costehorario, Validators.compose([Validators.pattern(this.NUMERIC_PATTREN)])],
      observaciones: [this.predata.observaciones],
      idactivo: this.predata.idactivo
    });
  }

  loadValues(){
    this.predata= {
      nombre: this.data.nombre,
      apellidos: this.data.apellidos,
      iddepartamento: this.data.iddepartamento,
      costehorario: this.data.costehorario,
      observaciones: this.data.observaciones,
      idactivo: this.data.idactivo
    };
  }

  saveData(){
    let object=null;
    if(this.data==null){
      object ={
        nombre: Utils.cleanSpaceString(this.dataform.value.nombre),
        apellidos: Utils.cleanSpaceString(this.dataform.value.apellidos),
        iddepartamento: this.dataform.value.iddepartamento,
        costehorario: Utils.cleanSpaceString(this.dataform.value.costehorario),
        observaciones: Utils.cleanSpaceString(this.dataform.value.observaciones),
        idactivo: this.predata.idactivo
      };
      this.anadir(object);
    }else{
      object ={
        identificacion: this.data.identificacion,
        nombre: Utils.cleanSpaceString(this.dataform.value.nombre),
        apellidos: Utils.cleanSpaceString(this.dataform.value.apellidos),
        iddepartamento: this.dataform.value.iddepartamento,
        costehorario: Utils.cleanSpaceString(this.dataform.value.costehorario),
        observaciones: Utils.cleanSpaceString(this.dataform.value.observaciones),
        idactivo: this.predata.idactivo
      };
      this.modificar(object);
    }
  }


  obtenerMensajeError(tipo: string){
    switch (tipo){
      case 'errorNombre':
        return 'Es necesario añadir un nombre';
      case 'errorApellidos':
        return 'Es necesario añadir un apellido';
      case 'errorDepartamento':
        return 'Es necesario seleccionar un departamento';
      case 'errorCostehorario':
        return 'El coste horario no tiene el formato adecuado';
      default:
        return 'Error desconocido';
    }
  }

}
