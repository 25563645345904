import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'app-info-status',
  templateUrl: './info-status.component.html',
  styleUrls: ['./../../form/form.css']
})
export class InfoStatusComponent implements OnInit {
  @Input() pagename: any;
  @Input() register: any;
  @Output() finishEvent = new EventEmitter<boolean>();

  constructor(private dataservice: DataService) { }

  ngOnInit() {
  }

  changeStatus(){
    console.log(this.register.idactivo);
    if(this.register.idactivo==1){
      this.register.idactivo=2;
    }else{
      this.register.idactivo=1;
    }
    this.dataservice.update(this.pagename, this.register)
    .subscribe(result=>{
      //this.refreshData();
      this.finishEvent.emit(true) 
    })
  }

}
