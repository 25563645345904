import { Injectable } from '@angular/core';
import { catchError, retry } from 'rxjs/operators';
import { serverUrl } from '../helper/url';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AutenticacionService } from '../module/login/service/autenticacion.service';
const options = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
const options2 = {
  headers: new HttpHeaders({'Accept': 'q=0.8;application/json;q=0.9'})
}


@Injectable({
  providedIn: 'root'
})
export class DataService {
  constructor(private http: HttpClient, private authenticacionService: AutenticacionService) { }

  /**
   * Función que permite obtener el listado de elementos(empresas, personas, equipos, centros)
   * @param service Cadena de caracter que indica nombre del servicio
   */
  getAll(service: string):Observable<any[]>{
    //return this.http.get<any[]>(serverUrl + '/' + service + '/all/1')
    return this.http.get<any[]>(`${serverUrl}/${service}/${this.authenticacionService.getEsquemaUser()}`)
    .pipe(
      //tap(res => console.log(res)),
      retry(3),
      catchError(err => {
        console.log(err);
        return of(err);
      })
    );
  }

  /**
   * Función que permite obtener un elemento(empresa, persona, equipo, centro) mediante identificador
   * @param service Cadena de caracteres que indica el nombre del servicio
   * @param id Identificar del elemento.
   */
  getById(service: string, id: string): Observable<any[]>{
    return this.http.get<any[]>(`${serverUrl}/${service}/${this.authenticacionService.getEsquemaUser()}/${id}`)
    .pipe(
      retry(3),
      catchError(err => {
        console.log(err);
        return of(err);
      })
    );
  }

  /**
   * Función que nos permite añadir un elemento. 
   * @param service Cadena de caracteres que indica el nombre del servicio
   * @param object Identificar del elemento.
   */
  add(service: string, object: Object):Observable<any[]>{
    let data = JSON.stringify(object);
    return this.http.post<any[]>(`${serverUrl}/${service}/add/${this.authenticacionService.getEsquemaUser()}`, data, options)
    .pipe(
      //tap(res => console.log(res)),
      catchError(err => {
        return of(err);
      })
    );
  }

  /**
   * Función que nos permite modificar un elemento.
   * @param service Cadena de caracteres que indica el nombre del servicio.
   * @param object Objeto que contiene los datos del elemento nuevo.
   */
  update(service: string, object: Object):Observable<any[]>{
    let data = JSON.stringify(object);
    return this.http.post<any[]>(`${serverUrl}/${service}/edit/${this.authenticacionService.getEsquemaUser()}`, data, options)
    .pipe(
      //tap(res => console.log(res)),
      catchError(err => {
        return of(err);
      })
    );
  }

  /**
   * Función que nos permite dar de baja un elemento.
   * @param service Cadena de caracteres que indica el nombre del servicio.
   * @param object Objeto que contiene los datos del elemento a dar de baja.
   */
  remove(service: string, object: Object):Observable<any[]>{
    let data= JSON.stringify(object);
    return this.http.post<any[]>(`${serverUrl}/${service}/delete/${this.authenticacionService.getEsquemaUser()}`, data, options)
    .pipe(
      catchError(err => {
        return of(err);
      })
    )
  }

}
