import { Injectable } from '@angular/core';
import { catchError, retry } from 'rxjs/operators';
import { serverUrl } from '../../../helper/url';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AutenticacionService } from '../../login/service/autenticacion.service';

@Injectable({
  providedIn: 'root'
})
export class AuxpeticionService {
  constructor(private http: HttpClient, private authenticacionService: AutenticacionService) { }
  
  getBloque(): Observable<any[]>{
    return this.http.get<any[]>(`${serverUrl}/ubicacion/bloque/${this.authenticacionService.getEsquemaUser()}`)
      .pipe(
        //tap(res => console.log(res)),
        catchError(err => {
          console.log(err);
          return of(err);
        })
      );
  }

  getArea(idbloque: number): Observable<any[]>{
    return this.http.get<any[]>(`${serverUrl}/ubicacion/area/${this.authenticacionService.getEsquemaUser()}?idbloque=${idbloque}`)
      .pipe(
        //tap(res => console.log(res)),
        catchError(err => {
          console.log(err);
          return of(err);
        })
      );
  }

  getPlanta(idbloque: number, idarea: number): Observable<any[]>{
    return this.http.get<any[]>(`${serverUrl}/ubicacion/planta/${this.authenticacionService.getEsquemaUser()}?idbloque=${idbloque}&idarea=${idarea}`)
      .pipe(
        //tap(res => console.log(res)),
        catchError(err => {
          console.log(err);
          return of(err);
        })
      );
  }

  getEstancia(idbloque: number, idarea: number, idplanta: number): Observable<any[]>{
    return this.http.get<any[]>(`${serverUrl}/ubicacion/estancia/${this.authenticacionService.getEsquemaUser()}?idbloque=${idbloque}&idarea=${idarea}&idplanta=${idplanta}`)
      .pipe(
        //tap(res => console.log(res)),
        catchError(err => {
          console.log(err);
          return of(err);
        })
      );
  }

  getEstanciaAll(){
    return this.http.get<any[]>(`${serverUrl}/ubicacion/estancia/all/${this.authenticacionService.getEsquemaUser()}`)
      .pipe(
        catchError(err =>{
          console.log(err);
          return of(err);
        })
      );
  }

  getPersonal(){
    return this.http.get<any[]>(`${serverUrl}/persona/departamento/${this.authenticacionService.getEsquemaUser()}`)
    .pipe(
      catchError(err =>{
        console.log(err);
        return of(err);
      })
    )
  }

  getCreador(){
    return this.http.get<any[]>(`${serverUrl}/peticion/creador/${this.authenticacionService.getEsquemaUser()}?idusuario=${this.authenticacionService.getIdUser()}`)
    .pipe(
      catchError(err =>{
        console.log(err);
        return of(err);
      })
    )
  }

  getPersonalById(idpeticion){
    return this.http.get<any[]>(`${serverUrl}/peticion/asignado/${this.authenticacionService.getEsquemaUser()}?identificacionpeticion=${idpeticion}`)
    .pipe(
      catchError(err =>{
        console.log(err);
        return of(err);
      })
    )
  }

  getCategoria(){
    return this.http.get<any[]>(`${serverUrl}/peticion/categoria/${this.authenticacionService.getEsquemaUser()}`)
    .pipe(
      catchError(err =>{
        console.log(err);
        return of(err);
      })
    )
  }

  getDuracion(){
    return this.http.get<any[]>(`${serverUrl}/peticion/duracion/${this.authenticacionService.getEsquemaUser()}`)
    .pipe(
      catchError(err =>{
        console.log(err);
        return of(err);
      })
    )
  }

  getPrioridadByReportador(idreportador){
    return this.http.get<any[]>(`${serverUrl}/ui/peticion/prioridad/${this.authenticacionService.getEsquemaUser()}?idreportador=${idreportador}`)
    .pipe(
      catchError(err =>{
        console.log(err);
        return of(err);
      })
    )
  }

}
