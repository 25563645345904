/*****************************************************/
/* EMPRESA:     Tecnología y Conocimiento            
/* Proyecto:    Minimiso                              
/* Autor:       Yeray Suárez
/* Archivo:     checklist.component.ts
/* Fecha:       20190320 
/* Vesion:      1.0.0 
/*****************************************************/
import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router) { }

    canActivate() {
        if (localStorage.getItem('dataUserPimbam')) {
            // logged in so return true
            return true;
        }
        this.router.navigate(['/login']);
        return false;
    }
}
