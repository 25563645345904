import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormComponent } from './form/form.component';
import { FormPeticionComponent } from './form-peticion/form-peticion.component';
import { FormAccessComponent } from './form-access/form-access.component';
import { FormPersonaComponent } from './form-persona/form-persona.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { AuxpeticionService } from './service/auxpeticion.service';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputTextModule } from 'primeng/inputtext';
@NgModule({
  declarations: [FormComponent, FormPeticionComponent, FormPersonaComponent, FormAccessComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    InputTextareaModule,
    MultiSelectModule,
    InputTextModule
  ],
  exports: [FormComponent, FormPeticionComponent, FormPersonaComponent, FormAccessComponent],
  providers: [AuxpeticionService],
})
export class FormModule { }
