import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { FormreportComponent } from './formreport.component';
import { ReporteService } from './service/reporte.service';

@NgModule({
  declarations: [FormreportComponent],
  imports: [
    CommonModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    InputTextareaModule,
    MultiSelectModule,
    CalendarModule
  ],
  exports: [FormreportComponent],
  providers: [ReporteService]
})
export class FormreportModule { }
