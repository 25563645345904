import { Component, OnInit } from '@angular/core';
import { ForgotpassService } from '../service/forgotpass.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-formforgotpass',
  templateUrl: './formforgotpass.component.html',
  styleUrls: ['./../forgotpass.css']
})
export class FormforgotpassComponent implements OnInit {
  public error=false;
  public error2=false;
  public error3=false;
  public error4=false;
  public info= false;
  public email='';
  
  constructor(private forgotpassService: ForgotpassService, private location: Location) {}

  ngOnInit() { 
  }

  enviarUrl(){
    if(this.email!='' && this.isMail(this.email)){
      this.forgotpassService.sendMail(this.email)
      .subscribe(result=>{
        if(result.error.error==true){
          this.error=true;
          this.error2=false;
          this.info=false;
          this.email=''
        }else{
          this.error=false;
          this.error2=false;
          this.info=true;
          this.email='';
        }
      })
    }else{
      this.error2=true;
    }
  }

  isMail(email: string){
    if(email.indexOf('@')!=-1 && email.indexOf('.')!=-1 ){
      return true;
    }
    return false;
  }

  cleanMessage(){
    this.error=false;
    this.error2=false;
    this.info=false;
  }

  goBack(){
    this.location.back();
  }
}
