/*****************************************************/
/* EMPRESA:     Tecnología y Conocimiento            
/* Proyecto:    Minimiso                              
/* Autor:       Yeray Suárez
/* Archivo:     helper.ts
/* Fecha:       20190320 
/* Vesion:      1.0.0 
/*****************************************************/
export default class Utils{
  /**
   * Función que nos permite clonar objetos
   * @param obj Objeto a clonar
   */
    static clone(obj: Object) {
        if (null == obj || "object" != typeof obj) return obj;     
        var copy = obj.constructor();     
        for (var attr in obj) {         
          if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];     
        }     
        return copy; 
    } 
    /**
     * Función que nos permite eliminar los espacios al principio y al final
     * @param value Valor a limpiar
     */
    static cleanSpaceString(value: any){
      if(value!=null && value!=''){
        return value.trim();
      }
      return value;
    }

    /**
     * Función que nos proporciona la fecha en string en un formato visible para el usuario.
     * @param fecha Fecha a formatear
     */
    static getDateFormated(fecha: object){
      if(fecha!=null){
        let day=fecha['date'].day;
        let month=fecha['date'].month;
        let year= fecha['date'].year;
        if(day<10){
          day= '0' + day;
        }
        if(month<10){
          month= '0' + month;
        }
        return `${year}/${month}/${day}`;
      }
      return null;
    }

} 
