import { Injectable } from '@angular/core';
import { serverUrl } from '../../../helper/url';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

const options = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ForgotpassService {

  constructor(private http: HttpClient) {}

  sendMail(email: string){
    let data = JSON.stringify({email: email});
    return this.http.post<any[]>(`${serverUrl}/pass/forgot`, data, options)
    .pipe(
      //tap(res => console.log(res)),
      catchError(err => {
        console.log(err);
        return of(err);
      })
    );  
  }

  sendNewPass(object: Object){
    let data = JSON.stringify(object);
    return this.http.post<any[]>(`${serverUrl}/pass/change`, data, options)
    .pipe(
      //tap(res => console.log(res)),
      catchError(err => {
        console.log(err);
        return of(err);
      })
    );
  }
}
