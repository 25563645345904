import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './component/home/home.component';

import { LoginModule } from './module/login/login.module';
import { TablaModule } from './module/tabla/tabla.module';
import { ForgotpassModule } from './module/forgotpass/forgotpass.module';

import { MultiSelectModule } from 'primeng/multiselect';
import { AuthGuard } from './guards/auth.guard';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './request.interceptor.service';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LoginModule,
    TablaModule,
    ForgotpassModule,
    MultiSelectModule,
  ],
  providers: [AuthGuard, 
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }