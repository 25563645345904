import { Component, OnInit, HostListener, ViewChild, ElementRef, Input } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FieldsService } from '../service/fields.service';
import { DataService } from 'src/app/service/data.service';
import { AutenticacionService } from '../../login/service/autenticacion.service';

@Component({
  selector: 'app-tabla',
  templateUrl: './tabla.component.html',
  styleUrls: ['./tabla.component.css']
})
export class TablaComponent implements OnInit {

  @ViewChild('dt', {static: false}) dt: ElementRef;
  public pagename: string;
  /**
   * Valor booleano que indica si es visible o no la tabla.
   */
  visible = true;
  /**
   * Valor que nos permite volver a la pagina de inicio de la tabla al cambiar de sección.
   */
  first = 0;
  /**
   * Valor booleano que nos permite mostrar o no el cargando.
   */
  loading=false;
  /**
   * Vector que almacena los datos asociados a las cabeceras de las tablas. 
   */
  fields: any[]=[];
  /**
   * Vector que almacena los datos contenidos en las tablas.
   */
  data: any[]=[];
  /**
   * Vector que almacena los nombres de las cabeceras a partir de fields.
   */  
  cols: any[]=[];
  /**
   * Vector que contiene los posibles estados de un registro de la tabla.
   */
  departamento: any[]=[];
  listdepartamento: any[]=[];
  estado: any[]=[];
  listestado:any[]=[];
  prioridad: any[]=[];
  listprioridad: any[]=[];
  estancia: any[]=[];
  categoria: any[]=[];
  duracion: any[]=[];
  privilegio: any[]=[];
  listprivilegio: any[]=[];
  creador: any[]=[];
  reportador: any[]=[];

  registerToDelete=null;
  registerToEdit= null;
  registerToStatus=null;
  interval: any;

  userprivilegios=null;
  @Input() mywidth: any;

  constructor(private route: ActivatedRoute, private fieldsservice: FieldsService, private dataservice: DataService, private autenticationService: AutenticacionService) {
  }

  ngOnInit() {
    this.userprivilegios=this.autenticationService.getPrivilegiosUser();
    console.log(this.userprivilegios);
    this.route.params.subscribe( params => {
      this.pagename=params.pagename;
      this.fields=[];
      if(this.pagename!='welcome'){
        this.loading=true;
        this.fieldsservice.getFields(this.pagename)
        .subscribe(result => {
          this.fields= result;
          this.cols=[];
          this.data=[];
          for(let f of this.fields){
            if(f.name!="eliminar" && f.name!="editar" && f.name!="imagen"){
              this.cols.push({field: f.name, header: f.caption});
            }
          }
          this.getContainsFilters();
        });
      }
    });
    
    if(this.interval){
        clearInterval(this.interval);
    }
    this.interval = setInterval(() => {
        this.refreshData();
        console.log('refresh');
    }, 60000);
  }

  /*ngOnDestroy(){
    clearInterval(this.interval);
  }*/


  getContainsFilters(){

    if(this.pagename=='peticion'){
    this.departamento=[];
    this.listdepartamento=[];
    this.estado=[];
    this.listestado=[];
    this.prioridad=[];
    this.listprioridad=[];
    this.estancia=[];
    this.duracion=[];
    this.categoria=[];

    this.fieldsservice.getFilters(this.pagename)
    .subscribe(result => {
      this.estado= result[0]['estados'];
      for(let r1 of result[0]['estados']){
        this.listestado.push({value: r1.label, label: r1.label});
      }
      this.listestado.unshift({value: null, label: '- Estado -'});
      this.estado.unshift({value: null, label: '- Estado -'});

      this.departamento= result[1]['departamentos'];
      for(let r2 of result[1]['departamentos']){
        this.listdepartamento.push({value: r2.label, label: r2.label});
      }
      this.listdepartamento.unshift({value: null, label: '- Departamento -'});
      this.departamento.unshift({value: null, label: '- Departamento -'});

      this.prioridad= result[2]['prioridades'];
      for(let r3 of result[2]['prioridades']){
        this.listprioridad.push({value: r3.label, label: r3.label});
      }
      this.listprioridad.unshift({value: null, label: '- Prioridad -'});
      this.prioridad.unshift({value: null, label: '- Prioridad -'});

      console.log('PRIORIDAD');
      console.log(this.prioridad);

      this.duracion=result[3]['duraciones'];
      this.duracion.unshift({value: null, label: '- Duración -'})
      this.categoria=result[4]['categorias'];
      this.categoria.unshift({value: null, label: '- Categoría -'});
      this.estancia=result[5]['ubicaciones'];
      this.estancia.unshift({value: null, label: '- Estancia -'});
      this.reportador=result[6]['reportador'];
      this.reportador.unshift({value: null, label: '- Reportador -'});
      this.creador=result[7]['creador'];
      this.creador.unshift({value: null, label: '- Creador -'});
      this.visible=true;
      this.loading=false;
      this.refreshData();
      })
    }
    if(this.pagename=='persona'){
      this.departamento=[];
      this.listdepartamento=[];
      this.fieldsservice.getFilters(this.pagename)
      .subscribe(result => {
        this.departamento= result;
        for(let r2 of result){
          this.listdepartamento.push({value: r2.label, label: r2.label});
        }
        this.listdepartamento.unshift({value: null, label: '- Departamento -'});
        this.departamento.unshift({value: null, label: '- Departamento -'});
        this.visible=true;
        this.loading=false;
        this.refreshData();
      });
    }
    if(this.pagename=='acceso'){
      this.privilegio=[];
      this.listprivilegio=[];
      this.fieldsservice.getFilters(this.pagename)
      .subscribe(result => {
        this.privilegio= result[0]['privilegios'];
        for(let r2 of this.privilegio){
          this.listprivilegio.push({value: r2.label, label: r2.label});
        }
        this.listprivilegio.unshift({value: null, label: '- Privilegio -'});
        this.privilegio.unshift({value: null, label: '- Privilegio -'});
        this.departamento= result[1]['departamentos'];
        for(let r2 of result[1]['departamentos']){
          this.listdepartamento.push({value: r2.label, label: r2.label});
        }
        this.listdepartamento.unshift({value: null, label: '- Departamento -'});
        this.departamento.unshift({value: null, label: '- Departamento -'});
        this.visible=true;
        this.loading=false;
        this.refreshData();
      });
    }
  }

  refreshData(){
    this.dataservice.getAll(this.pagename)
    .subscribe(result=>{
      this.data=result;
    })
  }


  saveRegisterToDelete(register: any){
    this.registerToDelete= register;
  }

  saveRegisterToStatus(register: any){
    this.registerToStatus= register;
  }


  /**
   * Almacena el registro que se desea editar en el atributo correspodiente. Para ser enviado al formulario que lo tratará.
   * @param register Registro completo de la tabla elegido para editar.
   */
  saveRegisterToEdit(register: any){
    this.registerToEdit= register;
  }

   /**
   * Evento llamado por los componentes hijo que contiene el componente table. En este caso sería cualquiera de los formularios.
   * @param $event Indica que el componente hijo sea cual sea ha terminado.
   */
  finishedChild($event){
    if($event=true){
      this.refreshData();
    }
  }



  getHtmlColor(text){
    switch(text){
      case 'Baja':
      case 'Media':
      case 'Alta':
        return text;
    }
  }
}
