import { Component, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DataService } from '../../../service/data.service';

@Component({
  template: ''
})
export class FormComponent {
  protected closeModal: ElementRef;
  @Input() nameservice: any;
  public waitingResult=false;
  /**
   * Vector que almacena todos los datos escritos en el formulario y verifica con una serie de reglas que se cumplen con los requisitos mínimos para ser aceptados.
   */
  public dataform: FormGroup;
  /**
   * Vector que almacena los datos de entrada de una empresa a editar.
   */
  public data: any=null;
  /**
   * Valor booleano que indica si ha habido un error al guardar los datos.
   */
/**
   * Cadena de caracteres que almacena el mensaje de error al producirse un error tras guardar los datos.
   */
  public errorMessage=''; 
  public errorSaving=false;
  public finishsearch=false;
  /**
   * Evento llamado por los componentes hijo que contiene el componente table. En este caso sería cualquiera de los formularios 
   * */ 
  @Output() finishEvent = new EventEmitter<boolean>(); 
  /**
   * Parámetro de entrada que nos permite obtener el registro a modificar del componente table y tratarlas.
   */
  @Input() set register(value: any){
    this.data= value;
    this.preload();
  }
  /**
   * Vector que almacena los datos para inicializar en dataform.
   */
  public predata;


  constructor(protected dataservice: DataService) { }

    /**
   * Esta función inicializa con initValues (si es nuevo registro) o loadValues (si se trata de una edición) los datos de la empresa para que posteriormente sean cargarlos en el formulario (empresaform) mediante startForm. Esta función también se encargará de buscar los subgrupos IAE en función de si se trata de un registro con el grupo IAE seleccionada o no. De hecho, se activa un evento para que cuando el usuario seleccione un grupo IAE se filtren las subgrupos IAE en función de este.
   */
  preload(){
  }

  setCloseModel(element){
    this.closeModal= element;
  }

  /**
   * Traslada al atributo empresadata lo que hay en el atributo predata.
   */
  startForm(){
  }

  /**
   * Función que inicializa el atributo predata a null o 0 en todos sus campos. Solo se llama si se trata de añadir una nueva empresa.
   */
  initValues(){
  }

  /**
   * Genera el objeto en función de empresaform a enviar a anadirEmpresa.
   */
  saveData(){
  }

    /**
   * Utiliza el servicio data.service para guardar los nuevos datos de una nueva empresa.
   * @param data Vector con los valores a añadir.
   */
  protected anadir(obj: Object){
    this.waitingResult=true;
    console.log('AÑADIR');
    this.dataservice.add(this.nameservice, obj)
    .subscribe(
      (result) => {
        if(result['status']==409){
          console.log(result);
          this.errorMessage= result['error']['error'];
          this.errorSaving=true;
        }else{
          this.initValues();
          this.startForm();
          this.closeModal.nativeElement.click(); 
          this.finishEvent.emit(true);
        }
        this.waitingResult=false;
      }
    );
  }

  protected modificar(obj: Object){
    this.waitingResult=true;
    this.dataservice.update(this.nameservice, obj)
    .subscribe(
      (result)=> {
        if(result['status']==409){
          this.errorSaving=true;
        }else{
          this.initValues();
          this.startForm();
          this.closeModal.nativeElement.click(); 
          this.finishEvent.emit(true);
        }
        this.waitingResult=false;
      },
    );
  }


  /**
   * Limpia el formulario para la siguiente posible apertura, ya sea de nuevo registro o de edición.
   */
  cleanDataNotSaved(){
    this.startForm();
    this.finishsearch=false;
    this.errorMessage='';
    this.errorSaving=false;
  }

  obtenerMensajeError(tipo: string){
  }
  
}
