import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'app-info-delete',
  templateUrl: './info-delete.component.html',
  styleUrls: ['./../../form/form.css']
})
export class InfoDeleteComponent implements OnInit {
  @Input() pagename: any;
  @Input() register: any;
  @Output() finishEvent = new EventEmitter<boolean>();

  constructor(private dataservice: DataService) {
  }

  ngOnInit() {
  }


  delete(){
    this.dataservice.remove(this.pagename, this.register)
    .subscribe(result=>{
      //this.refreshData();
      this.finishEvent.emit(true) 
    })
  }

}
