/*****************************************************/
/* EMPRESA:     Tecnología y Conocimiento            
/* Proyecto:    Tecnihotel                              
/* Autor:       Yeray Suárez
/* Archivo:     home.component.ts
/* Fecha:       20190621 
/* Vesion:      1.0.0 
/*****************************************************/
import { Component, OnInit, ViewEncapsulation, HostListener } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AutenticacionService } from '../../module/login/service/autenticacion.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

  /** 
  * Contructor de este componente, el cuál, genera el menú de cabecera de la aplicación tras superar la vista de login. Por lo tanto, este es el que lleva la navegación por las distintas partes de la aplicación. Además es el contendor del resto de vistas, salvo la del login.
  */
export class HomeComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  mywidth =null;
  /** 
   * Almacena la sección del menú seleccionada para enviarselo al resto de componentes contenidos en home.
   */
  public pagename: string;
  /**
   * Almacena, tras logearse, el nombre del usuario, para que sea mostrado por el template.
   */
  public usuario: string;
  /**
   * Almacena la clase del div contendor de la imagen del menú correspondiente a la sección empresa. Esta varía en función de si esta activa la sección o no.
   */
  public privilegios: any;

  public classEmpresa= 'empresa';
  /**
   * Almacena el nombre de la clase del div contenedor del nombre de la sección empresa del menu. Esta varía en función de si esta activa la sección o no.
   */
  public classTextEmpresa= 'inactivo';
  /**
   * Almacena la clase del div contendor de la imagen del menú correspondiente a la sección persona. Esta varía en función de si esta activa la sección o no.
   */
  public classPersona= 'persona';
  /**
   * Almacena el nombre de la clase del div contenedor del nombre de la sección persona del menu. Esta varía en función de si esta activa la sección o no.
   */
  public classTextPersona= 'inactivo';
  /**
   * Almacena la clase del div contendor de la imagen del menú correspondiente a la sección equipo. Esta varía en función de si esta activa la sección o no.
   */
  public classEquipo= 'equipo';
  /**
   * Almacena el nombre de la clase del div contenedor del nombre de la sección equipo del menu. Esta varía en función de si esta activa la sección o no.
   */
  public classTextEquipo= 'inactivo';
  /**
   * Almacena la clase del div contendor de la imagen del menú correspondiente a la sección centro. Esta varía en función de si esta activa la sección o no.
   */
  public classCentro= 'centro';
  /**
   * Almacena el nombre de la clase del div contenedor del nombre de la sección centro del menu. Esta varía en función de si esta activa la sección o no.
   */
  public classTextCentro= 'inactivo';
  /**
   * Almacena el nombre del tipo de contenido que va a determinar que va a mostrar el componente home
   */
  public contenido: string;

  constructor(
    private route: ActivatedRoute,
    private autenticacionservice: AutenticacionService
    //private autenticacionservice: AutenticacionService
  ) { }

/**
 * Evento que se lanza cuando se inicia el componente. En este caso lee la sección seleccionada previamente de la url.
 */
  ngOnInit() {
    this.mywidth =  window.innerWidth;
    console.log(this.mywidth);
    this.usuario= this.autenticacionservice.getUsername();
    this.privilegios= this.autenticacionservice.getPrivilegiosUser();
    this.route.params.subscribe( params => {
      this.contenido=params.contenedor;
      this.pagename=params.pagename;
    });
  }

/**
 * Función que devuelve verdadero o falso en función de si la sección seleccionada está registrada como tabla.
 */
  mostrarTabla(){
    if(this.contenido=='table'){
      return true;
    }
    return false;
  }
/**
 * Función que retorna verdadero o falso en función de si la sección seleccionada está registrada como checklist. 
 */  
  mostrarChecklist(){
    if(this.contenido=='checklist'){
      return true;
    }
    return false;
  }
  /**
   * Función que retorna verdadero o falso si se trata de la sección fichacentro.
   */
  mostrarFichaCentro(){
    if(this.contenido=='fichacentro'){
      return true;
    }
    return false;
  }
/**
 * Función que usa el servicio de atentificación para cerrar la sesión en la plataforma tanto en el backend como en el frontend.
 */
  salir(){
    this.autenticacionservice.rejectToken()
    .subscribe(result=>{
      console.log('finish');
    });
  }

  /**
   * Método que cambia la clase de los div contenedores de cada sección del menu para pasarlos a inactivos.
   * @param menu Valor que identifica que sección se debe desactivar.
   */
  unselectMenu(menu:number){
    switch(menu){
      case 1:{
        this.classEmpresa= 'empresa';
        this.classTextEmpresa= 'inactivo';
        break;
      }
      case 2:{
        this.classPersona= 'persona';
        this.classTextPersona= 'inactivo';
        break;
      }
      case 3:{
        this.classEquipo= 'equipo';
        this.classTextEquipo= 'inactivo';
        break;
      }
      case 4:{
        this.classCentro= 'centro';
        this.classTextCentro= 'inactivo';
        break;
      }
    }
  }

  /**
   * Método que cambia la clase de los div contenedores de cada sección del menu para pasarlos a activos y dejar el resto de secciones inactivas.
   * @param menu Valor que identifca que sección se debe desactivar.
   */
  selectMenu(menu: number){
    switch(menu){
      case 1:{
        this.classEmpresa= 'empresablanco';
        this.classTextEmpresa= 'activo';
        this.classPersona= 'persona';
        this.classTextPersona= 'inactivo';
        this.classEquipo= 'equipo';
        this.classTextEquipo= 'inactivo';
        this.classCentro= 'centro';
        this.classTextCentro= 'inactivo';
        break;
      }
      case 2:{
        this.classPersona= 'personablanco';
        this.classTextPersona= 'activo';
        this.classEmpresa= 'empresa';
        this.classTextEmpresa= 'inactivo';
        this.classEquipo= 'equipo';
        this.classTextEquipo= 'inactivo';
        this.classCentro= 'centro';
        this.classTextCentro= 'inactivo';
        break;
      }
      case 3:{
        this.classEquipo= 'equipoblanco';
        this.classTextEquipo= 'activo';
        this.classPersona= 'persona';
        this.classTextPersona= 'inactivo';
        this.classEmpresa= 'empresa';
        this.classTextEmpresa= 'inactivo';
        this.classCentro= 'centro';
        this.classTextCentro= 'inactivo';
        break;
      }
      case 4:{
        this.classCentro= 'centroblanco';
        this.classTextCentro= 'activo';
        this.classPersona= 'persona';
        this.classTextPersona= 'inactivo';
        this.classEmpresa= 'empresa';
        this.classTextEmpresa= 'inactivo';
        this.classEquipo= 'equipo';
        this.classTextEquipo= 'inactivo';
        break;
      }
    }
  }

  onResize(event){
    event.target.innerWidth; // window width
    this.mywidth =  event.target.innerWidth;
  }

}
